// Fonts
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../node_modules/select2/dist/css/select2.css";

// @import "diagnostic/diagnosticVoucher";

// @import "accounts/patientTransaction";
// @import "./select2.css";
@import "./sweetalert.css";	

